import React, { Suspense, useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

// theme layout
import Header from 'components/Header'
import Layout from 'components/Layout'

// pages
const HomePage = React.lazy(() => import('pages/HomePage'))
const AboutPage = React.lazy(() => import('pages/AboutPage'))
const ContactPage = React.lazy(() => import('pages/ContactPage'))
const EditorialPage = React.lazy(() => import('pages/EditorialPage'))
const EditorialDetailPage = React.lazy(() => import('pages/EditorialDetailPage'))
const PersonalPage = React.lazy(() => import('pages/PersonalPage'))
const PersonalDetailPage = React.lazy(() => import('pages/PersonalDetailPage'))
const PortraitPage = React.lazy(() => import('pages/PortraitPage'))
const PortraitDetailPage = React.lazy(() => import('pages/PortraitDetailPage'))
const ThesisDetailPage = React.lazy(() => import('pages/ThesisDetailPage'))
const ThesisPage = React.lazy(() => import('pages/ThesisPage'))

const AppRoutes = () => (
  <>
    <Suspense fallback={<div />}>
      <Routes>
        <Route element={<Layout />}>
          <Route element={<Header />}>
            {/* main tab */}
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            {/* sub tab */}
            <Route path="/thesis" element={<ThesisPage />} />
            <Route path="/editorial" element={<EditorialPage />} />
            <Route path="/portrait" element={<PortraitPage />} />
            <Route path="/personal" element={<PersonalPage />} />
          </Route>
          <Route path="/thesis/:guid" element={<ThesisDetailPage />} />
          <Route path="/editorial/:guid" element={<EditorialDetailPage />} />
          <Route path="/portrait/:guid" element={<PortraitDetailPage />} />
          <Route path="/personal/:guid" element={<PersonalDetailPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </Suspense>
  </>
)

export default AppRoutes
