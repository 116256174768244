import createSagaMiddleware from '@redux-saga/core'
import { configureStore } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'
import logger from 'redux-logger'

import { Env } from 'config/Env'
// list of reducers
import aboutReducer from 'features/about/store/about.slice'
import contactReducer from 'features/contact/store/contact.slice'
import editorialReducer from 'features/editorial/store/editorial.slice'
import homeReducer from 'features/home/store/home.slice'
import personalReducer from 'features/personal/store/personal.slice'
import portraitReducer from 'features/portrait/store/portrait.slice'
import thesisReducer from 'features/thesis/store/thesis.slice'
// saga
import { rootSaga } from 'store/rootSaga'

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
  reduxTravelling: Env.isDev(),
  savePreviousLocations: 100000,
})

const makeStore = () => {
  //
  const sagaMiddleware = createSagaMiddleware()
  const middlewares = [sagaMiddleware, routerMiddleware]
  if (!Env.isProd()) middlewares.push(logger)
  //
  const store = configureStore({
    reducer: {
      about: aboutReducer,
      contact: contactReducer,
      editorial: editorialReducer,
      home: homeReducer,
      router: routerReducer,
      personal: personalReducer,
      portrait: portraitReducer,
      thesis: thesisReducer,
    },
    devTools: Env.isDev(),
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(middlewares),
  })
  sagaMiddleware.run(rootSaga)
  return store
}

// store & history
export const store = makeStore()
export const history = createReduxHistory(store)

// types
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
