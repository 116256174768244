import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { createTodo, getTodos } from 'features/contact/api'
import { contactActions } from 'features/contact/store/contact.slice'
import { Todo } from 'features/contact/types'

// Worker Sagas
export function* onGetTodos(): SagaIterator {
  const todos: Todo[] = yield call(getTodos)
  yield put(contactActions.fetchAllSucceeded(todos))
}

function* onCreateTodo({
  payload,
}: {
  type: typeof contactActions.create
  payload: Todo
}): SagaIterator {
  yield call(createTodo, payload)
  yield put(contactActions.fetchAll())
}

// Watcher Saga
export function* contactWatcherSaga(): SagaIterator {
  yield takeEvery(contactActions.fetchAll.type, onGetTodos)
  yield takeEvery(contactActions.create.type, onCreateTodo)
}

export default contactWatcherSaga
