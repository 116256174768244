// DUCKS pattern
import { createAction, createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit'

import { Todo } from 'features/personal/types'
import type { RootState } from 'store/store'

export interface PersonalState {
  isLoading: boolean
  todos: Todo[]
  errors?: Error[]
}

const initialState: PersonalState = {
  isLoading: false,
  todos: [],
  errors: [],
}

// slice
export const personalSlice = createSlice({
  name: 'personal',
  initialState,
  reducers: {
    fetchAllSucceeded(state, action: PayloadAction<Todo[]>) {
      state.todos = action.payload
    },
  },
})

// Actions
export const personalActions = {
  create: createAction(`${personalSlice.name}/create`, (todo: Todo) => ({
    payload: { id: nanoid(), title: todo.title },
  })),
  fetchAll: createAction(`${personalSlice.name}/fetchAll`),
  fetchAllSucceeded: personalSlice.actions.fetchAllSucceeded,
}

// Selectors
export const selectTodos = (state: RootState) => state.personal.todos

// Selectors
export const selectPreviousLocations = (state: RootState) => state.router.previousLocations

// Reducer
export default personalSlice.reducer
