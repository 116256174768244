// DUCKS pattern
import { createAction, createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit'

import { Todo } from 'features/contact/types'
import type { RootState } from 'store/store'

export interface ContactState {
  isLoading: boolean
  todos: Todo[]
  errors?: Error[]
}

const initialState: ContactState = {
  isLoading: false,
  todos: [],
  errors: [],
}

// slice
export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    fetchAllSucceeded(state, action: PayloadAction<Todo[]>) {
      state.todos = action.payload
    },
  },
})

// Actions
export const contactActions = {
  create: createAction(`${contactSlice.name}/create`, (todo: Todo) => ({
    payload: { id: nanoid(), title: todo.title },
  })),
  fetchAll: createAction(`${contactSlice.name}/fetchAll`),
  fetchAllSucceeded: contactSlice.actions.fetchAllSucceeded,
}

// Selectors
export const selectTodos = (state: RootState) => state.contact.todos

// Reducer
export default contactSlice.reducer
