import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { createTodo, getTodos } from 'features/editorial/api'
import { editorialActions } from 'features/editorial/store/editorial.slice'
import { Todo } from 'features/editorial/types'

// Worker Sagas
export function* onGetTodos(): SagaIterator {
  const todos: Todo[] = yield call(getTodos)
  yield put(editorialActions.fetchAllSucceeded(todos))
}

function* onCreateTodo({
  payload,
}: {
  type: typeof editorialActions.create
  payload: Todo
}): SagaIterator {
  yield call(createTodo, payload)
  yield put(editorialActions.fetchAll())
}

// Watcher Saga
export function* editorialWatcherSaga(): SagaIterator {
  yield takeEvery(editorialActions.fetchAll.type, onGetTodos)
  yield takeEvery(editorialActions.create.type, onCreateTodo)
}

export default editorialWatcherSaga
