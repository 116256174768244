// DUCKS pattern
import { createAction, createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit'

import { Todo } from 'features/about/types'
import type { RootState } from 'store/store'

export interface AboutState {
  isLoading: boolean
  todos: Todo[]
  errors?: Error[]
}

const initialState: AboutState = {
  isLoading: false,
  todos: [],
  errors: [],
}

// slice
export const aboutSlice = createSlice({
  name: 'about',
  initialState,
  reducers: {
    fetchAllSucceeded(state, action: PayloadAction<Todo[]>) {
      state.todos = action.payload
    },
  },
})

// Actions
export const aboutActions = {
  create: createAction(`${aboutSlice.name}/create`, (todo: Todo) => ({
    payload: { id: nanoid(), title: todo.title },
  })),
  fetchAll: createAction(`${aboutSlice.name}/fetchAll`),
  fetchAllSucceeded: aboutSlice.actions.fetchAllSucceeded,
}

// Selectors
export const selectTodos = (state: RootState) => state.about.todos

// Reducer
export default aboutSlice.reducer
