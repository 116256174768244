import { all, fork } from 'redux-saga/effects'

import { aboutWatcherSaga } from 'features/about/store/about.sagas'
import { contactWatcherSaga } from 'features/contact/store/contact.sagas'
import { editorialWatcherSaga } from 'features/editorial/store/editorial.sagas'
import { homeWatcherSaga } from 'features/home/store/home.sagas'
import { personalWatcherSaga } from 'features/personal/store/personal.sagas'
import { portraitWatcherSaga } from 'features/portrait/store/portrait.sagas'
import { thesisWatcherSaga } from 'features/thesis/store/thesis.sagas'

export function* rootSaga() {
  // list of saga
  yield all([
    fork(homeWatcherSaga),
    fork(aboutWatcherSaga),
    fork(contactWatcherSaga),
    fork(thesisWatcherSaga),
    fork(editorialWatcherSaga),
    fork(portraitWatcherSaga),
    fork(personalWatcherSaga),
  ])
}

export default rootSaga
