import { SagaIterator } from '@redux-saga/core'
import { call, put, takeEvery } from 'redux-saga/effects'

import { createTodo, getTodos } from 'features/thesis/api'
import { thesisActions } from 'features/thesis/store/thesis.slice'
import { Todo } from 'features/thesis/types'

// Worker Sagas
export function* onGetTodos(): SagaIterator {
  const todos: Todo[] = yield call(getTodos)
  yield put(thesisActions.fetchAllSucceeded(todos))
}

function* onCreateTodo({
  payload,
}: {
  type: typeof thesisActions.create
  payload: Todo
}): SagaIterator {
  yield call(createTodo, payload)
  yield put(thesisActions.fetchAll())
}

// Watcher Saga
export function* thesisWatcherSaga(): SagaIterator {
  yield takeEvery(thesisActions.fetchAll.type, onGetTodos)
  yield takeEvery(thesisActions.create.type, onCreateTodo)
}

export default thesisWatcherSaga
